var EmbeddedVideo = {

    constants: {
        $youtube: $(".EmbeddedVideo-videoContainer")
    },

    asyncLoad: function () {
        var $youtube = EmbeddedVideo.constants.$youtube;

        $youtube.each(function (i) {

            let poster = $(this).find(".EmbeddedVideo-customPoster");
            if (poster.length == 0) {
                var source = "https://img.youtube.com/vi/" + $(this).data("embed") + "/maxresdefault.jpg";
                let defaultSrc = "https://img.youtube.com/vi/" + $(this).data("embed") + '/hqdefault.jpg';

                let img = jQuery('<img/>', {
                    src: source,
                    class: "img-fluid EmbeddedVideo-poster",
                }).on('load', function () {
                    if ($(this)[0].naturalWidth < 640 && $(this)[0].src !== defaultSrc) {
                        $(this).attr('src', defaultSrc);
                    }
                });
                img.appendTo($(this));
            }
        });

    },

    setupClickEvent: function () {
        var $youtube = EmbeddedVideo.constants.$youtube;

        $youtube.on("click", function () {
            var iframe = document.createElement("iframe");

            iframe.setAttribute("frameborder", "0");
            iframe.setAttribute("allowfullscreen", "");
            iframe.setAttribute("allow", "autoplay");
            iframe.setAttribute("class", "EmbeddedVideo-frame");
            iframe.setAttribute("src", "https://www.youtube.com/embed/" + this.dataset.embed + "?rel=0&showinfo=0&autoplay=1");

            this.innerHTML = "";
            this.appendChild(iframe);
        });

    },

    init: function () {
        EmbeddedVideo.asyncLoad();
        EmbeddedVideo.setupClickEvent();
    }
}

$(function () {    
    EmbeddedVideo.init();
});
